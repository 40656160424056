<template>
  <v-container fluid class="pa-5">
    <div>
      <h3 class="primary--text">Pilih Menu Panggil Pasien {{ namaPoli }}</h3>
      <!-- {{ message }} -->
      <v-row class="mt-2">
        <v-col cols="12" lg="3" sm="3" md="3">
          <v-select
            outlined
            return-object
            item-text="text"
            item-id="id"
            v-model="vmodelpanggilan"
            :items="item"
            dense
            @change="ubahlayar(vmodelpanggilan)"
            label="Nama Poli"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="2" sm="2" md="2">
          <v-text-field
            type="date"
            label="Tanggal Antrian"
            outlined
            @blur="cariPasien()"
            v-model="vmodeltanggal"
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" sm="4" md="4">
          <v-autocomplete
            v-model="vmodelNamaDokter"
            return-object
            item-text="text"
            item-value="id"
            deletable-chips
            dense
            chips
            outlined
            :items="itemsNamaDokter"
            :search-input.sync="searchDokter"
            label="Cari"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" lg="2" sm="2" md="2">
          <v-text-field
            v-model="vmodelNama"
            label="Nama Pasien"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="1" md="1" sm="1">
          <v-btn @click="cariPasien" class="btn-block" color="primary"
            >Cari <v-icon small>mdi-file-search-outline</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="12">
          <v-card elevation-7>
            <v-card-title>Data Pasien</v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"
                :server-items-length="total"
                :loading="loading"
                :items-per-page="limit"
                height="950pt"
                fixed-header
                :disable-sort="true"
                @update:page="fetchData"
                @update:options="updatePagination"
              >
                <template v-slot:item.status_panggilan="{ item }">
                  <td style="text-align: center">
                    <v-chip
                      dark
                      :color="setStatusPanggilWarna(item.status_panggilan)"
                      medium
                      >{{ setStatusPanggilText(item.status_panggilan) }}</v-chip
                    >
                  </td>
                </template>

                <template #[`item.sub_unit_name`]="{ item }">
                  <b>Dokter :</b> {{ item.sub_unit_name }}
                  <v-divider v-if="item.pay_by_classification === 'BPJS'" />
                  <b v-if="item.pay_by_classification === 'BPJS'">No.Sep :</b>
                  {{ item.refer_no }}
                  <v-divider></v-divider>
                  <b>Kode Booking :</b>&nbsp;
                  <v-chip
                    @click="liattaskid(item)"
                    color="teal darken-1"
                    dark
                    >{{ item.kode_booking }}</v-chip
                  >
                </template>

                <template #[`item.patient_name`]="{ item }">
                  <b>Nama Pasien :</b> {{ item.patient_name }}
                  <v-divider></v-divider>

                  <b>No.RM :</b>&nbsp;{{ item.medrec_no }}
                  <v-divider></v-divider>
                  <b>No.Register :</b> {{ item.registration_no }}
                  <v-divider></v-divider>
                  <b>ID REG :</b> {{ item.registration_id }}
                  <v-divider></v-divider>
                  <b>No.Antrian :</b>&nbsp;
                  <v-chip color="red darken-1" dark small>{{
                    item.q_number
                  }}</v-chip>
                  <v-divider></v-divider>
                  <b>Cara Bayar :</b> {{ item.pay_by_name }}
                </template>
                <template #[`item.folio_id`]="{ item }">
                  <v-btn
                    @click="panggil(item, 3)"
                    color="purple darken-1"
                    dark
                    :disabled="cekdisabled(item)"
                    class="btn-block ma-1"
                    large
                    >Pasien Datang &nbsp;<v-icon small>mdi-check</v-icon></v-btn
                  >
                  <v-btn
                    @click="panggil(item, 4)"
                    color="primary"
                    :disabled="cekdisabled(item)"
                    class="btn-block ma-1"
                    large
                    >Panggil &nbsp;<v-icon small
                      >mdi-room-service</v-icon
                    ></v-btn
                  >

                  <v-btn
                    @click="panggil(item, 5)"
                    color="blue darken-2"
                    dark
                    :disabled="cekdisabled(item)"
                    class="btn-block ma-1"
                    large
                    >Selesai &nbsp;<v-icon small>mdi-check</v-icon></v-btn
                  >
                  <v-btn
                    @click="panggil(item)"
                    dark
                    color="red darken-2"
                    class="btn-block ma-1"
                    large
                    >Batal &nbsp;<v-icon small>mdi-close-circle</v-icon></v-btn
                  >
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="showDialogTaskID" max-width="780">
      <v-card>
        <v-card-title class="headline"> STATUS TASK ID </v-card-title>
        <v-card-text>
          <ul class="list-group">
            <li class="list-group-item" v-for="(item,index) of listTaskID" :key="index">
              {{ item }}
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '@/plugins/supports'
export default {
  name: 'PanggilPasien',
  data () {
    return {
      vmodelpanggilan: '',
      vmodelNamaDokter: '',
      vmodeltanggal: '',
      listTaskID: [],
      vmodelNama: '',
      showDialogTaskID: false,
      message: '',
      progress: false,
      namaPoli: '',
      random_id:
        'client-' + Math.round(Math.random(100000000, 1000000000) * 1000000000),
      client: '',
      is_connect: false,
      locationId: '',
      searchDokter: '',
      itemsNamaDokter: [],
      // selected: '',
      item: [
        { id: 34, text: 'POLI TULIP' },
        { id: 35, text: 'POLI BOUGENVILE' },
        { id: 36, text: 'POLI ANGGREK' },
        { id: 37, text: 'POLI EDELWIS' },
        { id: 68, text: 'POLI EKSEKUTIF' }
      ],
      headers: [
        // Define your table headers here
        {
          text: 'Poli/Ruangan',
          value: 'sub_unit_name',
          width: '200px',
          align: 'left'
        },
        {
          text: 'Nama Pasien',
          value: 'patient_name',
          width: '200px',
          align: 'left'
        },
        {
          text: 'Aksi',
          value: 'folio_id',
          width: '100px',
          align: 'center'
        },
        {
          text: 'Status',
          value: 'status_panggilan',
          width: '10px',
          align: 'center'
        }
      ],
      items: [],
      search: '',
      tglPelayanan: '',
      loading5: false,
      search2: '',
      total: 0,
      startTime: null,
      elapsedTime: '',
      intervalId: null,
      page: 1,
      limit: 40,
      rbulan: '',
      loading: false
    }
  },
  created () {
    // this.ubahlayar()
    this.namaPoli = this.$route.query.namaPoli
    this.locationId = this.$route.query.locationId
    this.vmodelpanggilan = { id: this.locationId, text: this.namaPoli }
    this.jamSekarang()
    // eslint-disable-next-line no-undef

    setTimeout(() => {
      this.cariPasien()
    }, 100)
  },

  mounted () {
    setTimeout(() => {
      const randomId =
        'client-' + Math.round(Math.random() * 900000000 + 100000000)
      // eslint-disable-next-line no-undef
      this.client = new Paho.Client('broker.emqx.io', 8084, randomId)
      this.client.onConnectionLost = this.onConnectionLost
      this.client.onMessageArrived = this.onMessageArrived
      this.connectClient()
      // eslint-disable-next-line no-undef
      $('.v-text-field__details').removeClass('v-text-field__details')
      // eslint-disable-next-line no-undef
      $('.v-messages').removeClass('v-messages theme--light')
    }, 100)
  },
  methods: {
    cekdisabled (RESULTS) {
      const today = new Date()
      const yyyy = today.getFullYear()
      let mm = today.getMonth() + 1 // Months start at 0!
      let dd = today.getDate()

      if (dd < 10) dd = '0' + dd
      if (mm < 10) mm = '0' + mm

      // const formattedToday = dd + '/' + mm + '/' + yyyy
      const todays = yyyy + '-' + mm + '-' + dd

      const d1 = new Date(this.vmodeltanggal)
      const d2 = new Date(todays)

      if (d1.getTime() === d2.getTime()) {
        if (RESULTS.pay_by_classification === 'BPJS') {
          if (!RESULTS.refer_no) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        return true
      }
    },
    liattaskid (RESULTS) {
      console.log(RESULTS)
      this.showDialogTaskID = true
      // eslint-disable-next-line no-undef
      $.get(baseUrl + 'emr/list-task-id?kode=' + RESULTS.kode_booking, (res) => {
        // eslint-disable-next-line no-unused-vars
        const { con, msg, results } = res
        if (con) {
          this.listTaskID = results
        } else {
          this.listTaskID = []
        }
      }, 'JSON')
    },
    setStatusPanggilWarna (RESULTS) {
      if (RESULTS === 'panggil') {
        return '#26A69A'
      } else if (RESULTS === 'selesai') {
        return '#1565C0'
      } else if (RESULTS === 'batal') {
        return '#E53935'
      } else {
        return ''
      }
    },
    setStatusPanggilText (RESULTS) {
      if (RESULTS === 'panggil') {
        return 'DIPANGGIL'
      } else if (RESULTS === 'selesai') {
        return 'SELESAI'
      } else if (RESULTS === 'batal') {
        return 'BATAL'
      } else {
        return 'BELUM DIPANGGIL'
      }
    },
    connectClient () {
      console.log('Connecting to MQTT broker...')
      this.connectionStatus = 'Connecting...'
      this.client.connect({
        onSuccess: this.onConnect,
        onFailure: this.onFailure,
        useSSL: true
      })
    },
    onConnect () {
      this.isConnect = true
      this.connectionStatus = 'Connected'
      console.log('Connected to MQTT broker')
      this.client.subscribe('fauzitest')
    },
    onFailure (errorMessage) {
      this.isConnect = false
      this.connectionStatus = 'Connection Failed'
      console.error(
        'Failed to connect to MQTT broker:',
        errorMessage.errorMessage
      )
      setTimeout(this.connectClient, 5000) // Retry every 5 seconds
    },
    onConnectionLost (responseObject) {
      if (responseObject.errorCode !== 0) {
        console.error('Connection lost:', responseObject.errorMessage)
        this.isConnect = false
        this.connectionStatus = 'Connection Lost'
        this.connectClient() // Retry connecting immediately
      }
    },
    onMessageArrived (message) {
      console.log('onMessageArrived: ' + message.payloadString)
      // this.messages.push(message.payloadString)
      this.message = message
    },
    panggil (item, taskID) {
      // eslint-disable-next-line no-unused-vars
      const folioId = item.folio_id
      // eslint-disable-next-line no-unused-vars
      const kodeBooking = item.kode_booking
      // eslint-disable-next-line no-unused-vars
      const regId = item.registration_id
      // eslint-disable-next-line no-unused-vars
      const subUnitId = item.sub_unit_id
      // eslint-disable-next-line no-unused-vars
      const noRm = item.medrec_no
      // eslint-disable-next-line no-unused-vars
      const patientName = item.patient_name
      // eslint-disable-next-line no-unused-vars
      const qnumber = item.q_number

      const data = {
        kode_booking: kodeBooking,
        folio_id: folioId,
        regId: regId,
        task_id: taskID,
        sub_unit_id: subUnitId,
        noRm: noRm,
        patient_name: patientName,
        q_number: qnumber,
        setDokter: 1
      }
      console.log(data)

      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'panggil-pasien/kirim-task-id',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            // console.log(results)
            successMsg(msg)
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    ubahlayar (RESULTS) {
      // this.vmodelpanggilan =
      // console.log(RESULTS)
      // this.searchDokter()
      this.locationId = RESULTS.id

      this.$router.push({
        name: 'PANGGILPASIEN',
        query: {
          locationId: RESULTS.id,
          namaPoli: RESULTS.text
        }
      })

      this.$router.go(0)
    },
    jamSekarang () {
      const today = new Date()
      const yyyy = today.getFullYear()
      let mm = today.getMonth() + 1 // Months start at 0!
      let dd = today.getDate()

      if (dd < 10) dd = '0' + dd
      if (mm < 10) mm = '0' + mm

      // const formattedToday = dd + '/' + mm + '/' + yyyy
      const todays = yyyy + '-' + mm + '-' + dd
      // this.tglSekarang = formattedToday
      this.vmodeltanggal = todays
    },

    cariPasien () {
      // eslint-disable-next-line no-unused-vars
      // const data = {
      //   loc_id: this.locationId,
      //   tgl: this.vmodeltanggal,
      //   sub_unit_id: this.vmodelNamaDokter.id,
      //   nama_pasien: this.vmodelNama
      // }
      console.log(this.vmodelNamaDokter)
      this.fetchData(
        this.locationId,
        this.vmodeltanggal,
        !this.vmodelNamaDokter ? '' : this.vmodelNamaDokter.id,
        this.vmodelNama
      )
    },
    fetchData (locid, tgl, iddokter, nama) {
      // console.log(this.search2)
      this.loading = true
      const url =
        baseUrl +
        `panggil-pasien/index?page=${this.page}&limit=${this.limit}&loc_id=${locid}&tgl=${tgl}&sub_unit_id=${iddokter}&nama_pasien=${nama}`
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          this.items = data.items
          this.total = data.total
          this.loading = false
          // this.updateTimes()
          // this.startTimer() // Start the timer for periodic updates
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
          this.loading = false
        })
    },
    updatePagination (options) {
      this.limit = options.itemsPerPage
      this.page = options.page
      setTimeout(() => {
        this.fetchData(
          this.locationId,
          this.vmodeltanggal,
          !this.vmodelNamaDokter ? '' : this.vmodelNamaDokter.id,
          this.vmodelNama
        )
      }, 100)
    }
  },
  computed: {
    // searchDokter () {
    //   return true
    // }
  },
  watch: {
    searchDokter (val) {
      // console.log(val)
      const data = {
        location_id: this.locationId,
        searchTerm: this.vmodelNamaDokter.text
      }
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'panggil-pasien/nama-dokter-by-unit',
        data,
        (response) => {
          // if (con) {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = response
          if (con) {
            this.itemsNamaDokter = results
          } else {
            this.itemsNamaDokter = []
          }
        },
        'JSON'
      )
    }
  }
}
</script>
<style scoped>
hr {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  width: 100%;
  padding: 2px 10px 0px 10px !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 1px !important;
}
</style>
